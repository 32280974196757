<template>
    <div class="header__main">
        <div class="container grid grid--middle">
            <div class="col header__main-logo-col">
                <NuxtLink
                    :to="
                        !$hasRole(RoleTypes.SUPPLIER)
                            ? NuxtLinkNameTypes.HOME
                            : NuxtLinkNameTypes.ORDER_HISTORY_PAGE_SMO
                    "
                    class="header__logo"
                >
                    <AtomIcon name="logo-smart-order" />
                </NuxtLink>
            </div>
            <div class="col col--expand">
                <MoleculeHeaderSearch />
            </div>
            <div class="col">
                <ul class="header__actions">
                    <li>
                        <AtomButton class="header__action" type="tertiary" size="reset" @click="redirectToHelpDesk">
                            <AtomIcon name="faq" class="header__action__icon" width="20" />
                            <span class="header__action__text">
                                {{ $t('General.help_and_service') }}
                            </span>
                        </AtomButton>
                    </li>
                    <li v-if="useStore().isVoStore()" v-role:not="RoleTypes.SUPPLIER">
                        <AtomButton
                            class="header__action"
                            type="tertiary"
                            size="reset"
                            :title="$t('General.sms_title_name')"
                            @click="navigate(NuxtLinkNameTypes.SMS)"
                        >
                            <AtomIcon name="grid-max" class="header__action__icon" width="20" />
                            <span class="header__action__text" :title="$t('General.sms_title_name')">
                                {{ $t('General.sms_title') }}
                            </span>
                        </AtomButton>
                    </li>
                    <li v-if="config.public.workbookCatalogId" v-role:not="RoleTypes.SUPPLIER">
                        <AtomButton
                            class="header__action"
                            type="tertiary"
                            size="reset"
                            @click="navigate(NuxtLinkNameTypes.WORKBOOK_PAGE)"
                        >
                            <AtomIcon name="book-open" class="header__action__icon" width="20" />
                            <span class="header__action__text">
                                {{ $t('Workbook.meta') }}
                            </span>
                        </AtomButton>
                    </li>
                    <li v-role:not="RoleTypes.SUPPLIER">
                        <AtomButton
                            class="header__action notes-button"
                            type="tertiary"
                            size="reset"
                            @click="navigate(NuxtLinkNameTypes.NOTES_PAGE)"
                        >
                            <AtomIcon name="edit" class="header__action__icon" width="20" />
                            <span class="header__action__text">
                                {{ $t('General.notes') }}
                            </span>
                        </AtomButton>
                    </li>
                    <MoleculeShoppingCartFlyout
                        v-if="orderPermissions || ($hasRole(RoleTypes.MEMBER) && !orderPermissions)"
                        v-role:not="RoleTypes.SUPPLIER"
                        :show-in-matrix="false"
                    />
                    <MoleculeAccountFlyout />
                </ul>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { RoleTypes } from '~/composables/types/roleTypes'
import { NuxtLinkNameTypes } from '~/composables/types/nuxtHyperlinkTypes'
import useStore from '~/composables/service/useStore'

const orderPermissions = ref(false)
const config = useRuntimeConfig()
const { $hasRole, $t } = useNuxtApp()
const redirectToHelpDesk = () => {
    window.open($t('General.help_and_service_route'), '_blank')
}

const navigate = (link: string) => {
    navigateTo(link)
}

onMounted(() => {
    const { $can } = useNuxtApp()
    orderPermissions.value = $can(PermissionTypes.ORDER)
})
</script>
<style lang="scss" scoped>
.header {
    &__main {
        padding-top: 1.125rem;
        padding-bottom: 1.5rem;

        &-logo-col {
            width: rem(82);
            margin-right: sp(m);
        }
    }

    &__logo {
        @include helper-breakpoint-media-min($xl) {
            margin-right: sp(xxl);
        }

        .svg-icon {
            width: rem(82);
            height: rem(48);
        }
    }

    &__actions {
        margin-left: 3.5rem;
        height: rem(49);

        @include helper-breakpoint-media-max($lg) {
            margin-top: sp(s);
            margin-left: 0;
        }

        li {
            display: inline-block;

            & + li {
                margin-left: 0.875rem;
            }

            .header__action {
                color: $setting-color-corporate-blue;
                cursor: pointer;
                background-color: transparent;

                .svg-icon {
                    line-height: 1;
                }

                &__text {
                    display: block;
                    font-size: map-get($setting-font-size, default);
                    font-weight: map-get($setting-font-weight, regular);
                    margin-top: 0.375rem;
                    white-space: nowrap;
                }
            }
        }
    }
}
</style>
