<template>
    <div class="footer">
        <div class="container">
            <div class="footer__row">
                <div class="footer__column">
                    <div class="footer__column__title">
                        {{ $t('General.intersport_portals') }}
                    </div>
                    <p class="footer__column__text">
                        {{ $t('Footer.footer_portal_text') }}
                    </p>
                    <ul v-if="platforms">
                        <template v-for="(store, index) in platforms || []" :key="index">
                            <li v-if="store?.attributes">
                                <a :href="store.attributes.link">{{ store.attributes.name }}</a>
                            </li>
                        </template>
                    </ul>
                </div>

                <template v-for="(node, index) in navigationNodes" :key="index">
                    <div class="footer__column">
                        <div class="footer__column__title">
                            {{ node.title }}
                        </div>
                        <p v-if="node.children[0]?.nodeType === 'label'" class="footer__column__text">
                            {{ node.children[0].title }}
                        </p>

                        <ul v-if="node.children.length">
                            <template
                                v-for="(child, childIndex) in node.children.filter(
                                    (a) => a.nodeType === 'link' || a.nodeType === 'external_url'
                                )"
                                :key="childIndex"
                            >
                                <li>
                                    <NuxtLink v-if="child.nodeType === 'link'" :to="child.url">
                                        {{ child.title }}
                                    </NuxtLink>
                                    <a v-else :href="parseNodeExternalUrl(child.url)" target="_blank">
                                        {{ child.title }}
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </template>
            </div>
        </div>
        <div class="footer__bar">
            <div class="container">
                <div v-if="footerBarItems" class="footer__bar__items">
                    <div class="footer__bar__item">
                        {{ $sanitizeHtml($t('Footer.intersport_germany')) }}
                    </div>
                    <template v-for="(item, index) in footerBarItems" :key="index">
                        <div class="footer__bar__item">
                            <NuxtLink class="footer__bar__link" :to="'/' + item.link">
                                {{ item.text }}
                            </NuxtLink>
                        </div>
                    </template>
                    <div v-if="config.public.userCentricsIsActive" class="footer__bar__item">
                        <a class="footer__bar__link" href="#uc-banner-show">{{ $t('Footer.cookie_settings') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
const { loadNavigation, nodes: navigationNodes } = useNavigation({
    id: 'FOOTER_NAVIGATION_PRE_ORDER',
})
const platformsStore = usePlatformsStore()
const staticPages = useStaticPages()
const { $sanitizeHtml, $t } = useNuxtApp()
const config = useRuntimeConfig()
const desktopMin = 1024

const { platforms } = storeToRefs(platformsStore)
const isMobile = ref(false)
const footerBarItems = ref([
    {
        text: $t('Footer.imprint'),
        link: staticPages.getPageBySlug('imprint')?.slug,
    },
    {
        text: $t('Footer.privacy'),
        link: staticPages.getPageBySlug('privacy-policy')?.slug,
    },
])

const onResizeHandler = () => {
    isMobile.value = window.innerWidth < desktopMin
}

const parseNodeExternalUrl = (link: string) => link.replace('http://tel', 'tel:').replace('http://mailto', 'mailto:')

onMounted(async () => {
    await loadNavigation()
    await platformsStore.getPlatforms()

    window.addEventListener('resize', onResizeHandler)
    window.dispatchEvent(new Event('resize'))
})

onUnmounted(() => {
    window.removeEventListener('resize', onResizeHandler)
})
</script>

<style lang="scss">
.footer {
    @include helper-color(white);
    @include helper-color-bg(strong-blue);

    &__row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1.5rem;
        padding-top: 2.5rem;
        padding-bottom: 1.5rem;
    }

    &__bar {
        padding: rem(24) 0;
        border-top: rem(1) solid $setting-color-white;

        &__items {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include helper-color(white);
        }

        &__link {
            line-height: map-get($setting-font-line-height, default);

            @include helper-color(white);

            &:hover {
                text-decoration: underline;
            }
        }

        &__item:nth-child(1) {
            width: 28%;
        }
    }

    &__column {
        &__title {
            font-size: map-get($setting-font-size, 3);
            font-weight: map-get($setting-font-weight, medium);
        }

        &__text {
            margin-bottom: 1.5rem;
        }

        ul {
            li {
                a {
                    display: block;
                    line-height: map-get($setting-font-line-height, default);

                    @include helper-color(white);
                    @include helper-font-weight(medium);
                }

                & + li {
                    margin-top: 0.5rem;
                }
            }
        }
    }
}
</style>
