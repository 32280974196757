<template>
    <div class="transfers" :class="{ 'is-expanded': isExpanded, 'is-hiding': isHidden }">
        <div class="transfers__header" @click="isExpanded = !isExpanded">
            <div class="transfers__status">
                <AtomIcon v-if="isInProgress" name="loader-ring" width="24" class="is-loading" />
                <AtomIcon v-else-if="isDone && hasErrors" name="alert-triangle" width="24" class="is-warning" />
                <AtomIcon v-else name="check-circle" width="24" class="is-done" />
            </div>

            <h2 class="transfers__title">
                {{ getTransferTitle() }}
            </h2>

            <button type="button" class="transfers__expand-button">
                <AtomIcon name="chevron-up" width="24" />
            </button>
        </div>

        <div class="transfers__content">
            <div class="transfers__content_inner">
                <p v-if="!isReexport">
                    <template v-if="isBranchCluster">
                        <template v-if="isInProgress">{{ $t('General.uploads_loading_text') }}</template>
                        <template v-else-if="isDone && hasErrors">{{ $t('General.uploads_error_text') }}</template>
                        <template v-else>{{ $t('General.uploads_done_text') }}</template>
                    </template>
                    <template v-else>
                        <template v-if="isInProgress">{{ $t('General.downloads_loading_text') }}</template>
                        <template v-else-if="isDone && hasErrors">{{ $t('General.downloads_error_text') }}</template>
                        <template v-else>{{ $t('General.downloads_done_text') }}</template>
                    </template>
                </p>

                <ul class="transfers__items">
                    <template v-for="(item, index) in items" :key="index">
                        <li class="transfers__item flex items-center">
                            <AtomIcon
                                v-if="item.isLoaded && item.hasError"
                                name="alert-triangle"
                                bolding="1.25"
                                class="status--warning"
                            />

                            <span class="flex-1">{{ getItemName(item.name) }}</span>

                            <AtomIcon
                                v-if="item.isLoaded && item.hasError"
                                name="rotate-ccw"
                                bolding="1.25"
                                class="action action--retry"
                                @click="store.retry(item)"
                            />
                            <AtomIcon
                                v-else-if="item.isLoaded"
                                name="check-circle"
                                bolding="1.25"
                                class="status--done"
                            />
                            <button v-else type="button" class="action action--cancel" @click="store.cancel(item)">
                                {{ $t('General.cancel') }}
                            </button>
                        </li>
                    </template>
                </ul>

                <div class="text-center">
                    <button v-if="!isDone" type="button" class="transfers__cancel-all" @click="store.cancelAll()">
                        {{ $t('General.cancel_all') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { AcceptedRequestResponse, TransferItem } from '~/composables/stores/useTransferStore'

const HIDE_TIMEOUT = 3000

const { $emitter, $t } = useNuxtApp()
const store = useTransferStore()
const { items, loadedItems, errorItems, isInProgress, isDone, hasErrors } = storeToRefs(store)

const isExpanded = ref(false)
const isHidden = ref(store.items.length === 0)

let hiddenTimeout: ReturnType<typeof setTimeout> | null = null
let lastItemsLength = store.items.length

const isReexport = computed(() => items.value?.[0]?.request?.endpoint === 'reexport')
const isBranchCluster = computed(() => items.value?.[0]?.request?.endpoint === 'branch-clusters')

const afterProcessEnded = () => {
    if (isDone.value && !hasErrors.value) {
        isExpanded.value = false

        hiddenTimeout = setTimeout(() => {
            hiddenTimeout = null

            if (!isExpanded.value) {
                items.value = []
            }
        }, HIDE_TIMEOUT)
    } else if (isDone.value && hasErrors.value) {
        isExpanded.value = true
    }
}

const getTransferTitle = (): string => {
    if (isReexport.value) {
        return $t('MyOrderDetails.reexport_started')
    }

    const isUpload = isBranchCluster.value
    const action = isUpload ? 'upload' : 'download'
    const status = isDone ? 'ended' : 'started'
    const totalItems = items.value.length
    let processedItems

    if (isDone.value) {
        if (hasErrors.value) {
            processedItems = totalItems - errorItems.value.length
        } else {
            processedItems = totalItems
        }
    } else {
        processedItems = Math.max(1, loadedItems.value.length)
    }

    return $t(`General.${action}_${status}`, [totalItems > 1 ? `${processedItems} / ${totalItems}` : ''])
}

const getItemName = (name: string): string => {
    if (isReexport.value) {
        return !hasErrors.value ? $t('MyOrderDetails.reexport_triggered') : $t('MyOrderDetails.reexport_trigger_failed')
    }

    return name
}

store.$subscribe(() => {
    if (items.value.length > lastItemsLength) {
        if (hiddenTimeout) {
            clearTimeout(hiddenTimeout)
            hiddenTimeout = null
        }
        isHidden.value = false
        lastItemsLength = items.value.length
    }
})

watch(loadedItems, (newValue: TransferItem[]) => {
    if (newValue.length) {
        const item = newValue.find((itm: TransferItem) => !itm.isTransferred)

        if (!item) {
            return
        }

        if (item.request.response && !('error' in item.request.response)) {
            const response = item.request.response as AcceptedRequestResponse
            const elem = document.createElement('a')

            elem.href = `data:application/${response.type};base64,${response.data}`
            elem.download = response.filename ?? ''
            elem.target = '_blank'
            document.body.appendChild(elem)
            elem.click()
            elem.remove()
            item.isTransferred = true
            item.name = response.filename ?? ''
        }

        afterProcessEnded()
        $emitter.$emit('downloadComplete')
    }
})

watch(items, (newValue: TransferItem[], oldValue: TransferItem[]) => {
    if (newValue.length && oldValue.length > newValue.length) {
        afterProcessEnded()
    } else if (!newValue.length) {
        isExpanded.value = false
        isHidden.value = true
    }
})

watch(isExpanded, (newValue: boolean) => {
    if (!newValue && isDone.value && !hiddenTimeout) {
        items.value = []
    }
})

watch(isHidden, (newValue: boolean) => {
    if (newValue) {
        lastItemsLength = 0
    }
})
</script>

<style lang="scss" scoped>
.transfers {
    position: fixed;
    width: rem(345);
    max-width: 90%;
    bottom: 0;
    right: sp(xl);
    border-bottom: 0;
    box-shadow: 0 2px 6px rgba(#000, 0.1);
    transition: 0.3s;
    transition-property: transform, opacity;
    z-index: $setting-zi-modal;

    @include helper-color-bg(white);
    @include helper-border($setting-color-default-line);
    @include helper-border-radius-t($setting-border-radius-10);

    button {
        background-color: transparent;
        cursor: pointer;
    }

    &__header {
        gap: sp(xs);
        padding: sp(xs2) sp(s);
        cursor: pointer;

        @include helper-flex-centered();
    }

    &__title {
        flex: 1;

        @include helper-font-weight(medium);
        @include helper-font-size(default);
    }

    &__status {
        .is-loading {
            :deep(svg) {
                @include helper-effect-default-rotation();
            }
        }

        .is-done {
            @include helper-color(alert-success);
        }

        .is-warning {
            @include helper-color(alert-warning);
        }
    }

    &__expand-button {
        transition: transform 0.3s;

        @include helper-color(corporate-blue);
    }

    &__content {
        display: grid;
        grid-template-rows: 0fr;
        padding: 0 sp(s);
        transition: grid-template-rows 0.2s;

        @include helper-color(text-primary);
        @include helper-font-weight(regular);

        &_inner {
            overflow: hidden;
        }

        p {
            margin: 0 0 sp(xs2);
        }
    }

    &__items {
        max-height: rem(120);
        overflow: auto;
    }

    &__item {
        padding: sp(xs) 0;
        gap: sp(xxs);

        @include helper-border-b($setting-color-default-line);

        &:first-child {
            @include helper-border-t($setting-color-default-line);
        }

        .status {
            &--done {
                @include helper-color(alert-success);
            }

            &--warning {
                @include helper-color(alert-warning);
            }
        }

        .action {
            @include helper-color(corporate-blue);
            @include helper-font-weight(medium);
            @include helper-border-b($setting-color-corporate-blue);

            &--retry {
                cursor: pointer;
                border: 0;
            }
        }
    }

    &__cancel-all {
        margin-top: rem(10);
        margin-bottom: sp(xs2);

        @include helper-color(corporate-blue);
        @include helper-font-weight(medium);
        @include helper-border-b($setting-color-corporate-blue);

        &:disabled {
            border-color: $setting-color-state-disabled-text;
            pointer-events: none;

            @include helper-color(state-disabled-text);
        }
    }

    &.is-expanded {
        .transfers {
            &__expand-button {
                transform: rotate(180deg);
            }

            &__content {
                grid-template-rows: 1fr;
            }
        }
    }

    &.is-hiding {
        transform: translateY(100%);
        opacity: 0;
        pointer-events: none;
    }
}
</style>
