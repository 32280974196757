export interface ConsentsInterface {
    [key: string]: string
}

export const useCookieConsents = defineStore('cookieConsents', {
    state: () => ({
        consents: {} as any,
    }),
    actions: {
        update(value: string, key: string) {
            const newRef = { [key]: value }
            this.consents = { ...this.consents, ...newRef }

            return this.consents[key]
        },
        remove(key: string): void {
            delete this.consents[key]
        },
        get(key: string): any {
            return this.consents[key]
        },
    },
})
