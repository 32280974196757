<template>
    <div
        :class="{
            'meta-bar': true,
            'meta-bar-no': useStore().isNoStore(),
            'dev-background': devRelease,
            'dev-background-no': devRelease && useStore().isNoStore(),
            disabled: disabled,
        }"
    >
        <div class="meta-bar__container container e2e-meta-bar-cart-container">
            <div class="meta-bar__left">
                <AtomMetaBarPlatformSwitch />
            </div>
            <div class="meta-bar__left">
                <AtomMetaBarUserOptions />
            </div>
            <div
                v-if="$can(PermissionTypes.ORDER) || $hasRole(RoleTypes.MEMBER)"
                v-role:not="RoleTypes.SUPPLIER"
                class="meta-bar__active-cart-container"
            >
                <cart-meta-bar-micro-frontend
                    :api-base-url="config.public.ccApiCartServiceUrl"
                    :auth-token="accessToken"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { EnvironmentTypes } from '~/composables/types/environmentTypes'
import type { AdditionalOrderFieldGroup } from '~/composables/types/api/searchDiscover'
import { PermissionTypes } from '~/composables/types/permissionTypes'
import { RoleTypes } from '~/composables/types/roleTypes'
import { ccMicroFrontend } from '~/composables/service/ccMicroFrontend'

const { $emitter, $hasRole } = useNuxtApp()
const cartsStore = useCartsStore()
const { getCachedData } = useLocalStorageCache<AdditionalOrderFieldGroup[]>(LocalStorageNameTypes.ORDER_FIELDS_KEY)
const { loadAdditionalOrderFieldsData } = useAdditionalOrderFields()
const config = useRuntimeConfig()
const devRelease = config.public.releaseStage !== EnvironmentTypes.prod
const disabled = ref(false)
const { $can } = useNuxtApp()
const { getAccessToken } = ccMicroFrontend()
const accessToken = getAccessToken()

const onMatrixModalOpened = () => {
    disabled.value = true
}

const onMatrixModalClosed = () => {
    disabled.value = false
}

const updateActiveCartStorage = (event: Event) => {
    if (event instanceof CustomEvent) {
        nextTick(() => {
            if (cartsStore.updateCachedCart) {
                cartsStore.updateCachedCart(event.detail.id, event.detail.name, event.detail.state)
            }
        })
    }
}

onMounted(async () => {
    window.addEventListener('updateActiveCartStorage', updateActiveCartStorage)

    if (!getCachedData()) {
        await loadAdditionalOrderFieldsData()
    }

    $emitter.$on('matrixModal.opened', onMatrixModalOpened)
    $emitter.$on('matrixModal.closed', onMatrixModalClosed)
})

onBeforeUnmount(() => {
    $emitter.$off('matrixModal.opened', onMatrixModalOpened)
    $emitter.$off('matrixModal.closed', onMatrixModalClosed)
    window.removeEventListener('updateActiveCartStorage', updateActiveCartStorage)
})
</script>

<style lang="scss" scoped>
.meta-bar {
    position: sticky;
    top: 0;
    left: 0;
    height: sp(l);
    width: 100%;
    z-index: $setting-zi-header-metabar;
    display: flex;
    justify-content: center;

    @include helper-color-bg(light-gray);

    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: sp(s);
    }

    &__left {
        overflow: visible;
        display: flex;
        align-items: center;
    }

    &__active-cart-container {
        margin-left: auto;
    }

    &.disabled {
        pointer-events: none;
    }
}

.meta-bar-no {
    @include helper-color(white);
    @include helper-color-bg(strong-blue);

    :deep(.meta-bar-button__text),
    :deep(.meta-bar-button__icon) {
        @include helper-color(white);
    }

    :deep(.meta-bar-button__arrow) {
        svg {
            fill: $setting-color-white;
        }
    }
}

.dev-background {
    @include helper-color-bg(alert-warning);

    background-image: linear-gradient(
        -45deg,
        rgba(#fff, 0.2) 25%,
        25%,
        transparent 50%,
        50%,
        rgba(#fff, 0.2) 75%,
        75%,
        transparent
    );
    background-size: rem(50) rem(50);
    box-shadow:
        inset 0 0 rem(10) rgba(#fff, 0.3),
        inset 0 0 rem(5) rgb(0 0 0 / 40%);
}

.dev-background-no {
    @include helper-color-bg(strong-blue);

    background-image: linear-gradient(
        -45deg,
        rgba(#fff, 0.4) 25%,
        25%,
        transparent 50%,
        50%,
        rgba(#fff, 0.4) 75%,
        75%,
        transparent
    );
}
</style>
