<template>
    <AtomMetaBarButton
        v-if="useStore().isNoStore()"
        :text="$t('General.options')"
        icon="manage-accounts"
        :show-arrow="true"
        :arrow-up="flyoutOpen"
        icon-width="24"
        @click="flyoutOpen = !flyoutOpen"
    >
        <div class="flyout-container">
            <AtomFlyout v-model="flyoutOpen" :close-on-outside-click="true" arrow-position="left" :margin="20">
                <AtomFlyoutHeader :text="$t('General.options')" />
                <AtomFlyoutItem>
                    <div class="preference-item">
                        <AtomToggle v-model="currentToggleState" @update:model-value="updateUserPricePreferences" />
                        <span>{{ $t('General.show_prices') }}</span>
                    </div>
                </AtomFlyoutItem>
            </AtomFlyout>
        </div>
    </AtomMetaBarButton>
</template>

<script lang="ts" setup>
import { usePreferencesStore } from '~/composables/stores/usePreferencesStore'

const { $t } = useNuxtApp()
const currentToggleState = ref(false)
const flyoutOpen = ref(false)

const updateUserPricePreferences = (active: boolean = false) => {
    usePreferencesStore().updateShowNOEKPrices(active)
}

onMounted(async () => {
    currentToggleState.value = usePreferencesStore().showNoEKPrices
})
</script>

<style lang="scss" scoped>
.flyout-container {
    position: absolute;
}

.flyout-item a {
    display: flex;
    align-items: center;
    color: $setting-color-dark-blue;

    svg {
        margin-left: rem(6);

        @include helper-color(corporate-blue);
    }
}

.preference-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: sp(xs);
}
</style>
