import type { PlatformsResponse } from '~/composables/types/api/searchDiscover/header'
import handleError from '~/composables/api/handleResponse'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function (): Promise<any> {
    const url = `/customer-platforms/`
    const { fetchSdApi } = fetchOrderDetermination()

    try {
        const response = (await fetchSdApi(url)) as PlatformsResponse

        return response.data ? response.data : []
    } catch (error) {
        return handleError(error)
    }
}
