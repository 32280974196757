<template>
    <AtomModal
        :open="!!abstractProductIdOrSku"
        :buttons="modalButtons"
        :is-matrix-modal="true"
        :prevent-background-scroll="true"
        class="matrix-modal"
        :class="{
            'matrix-modal--with-nav': navList && navList.length > 1,
            'matrix-modal--loading': isLoading,
            'matrix-modal--side-buttons': !showSideButtons,
        }"
        @button-clicked="onModalButtonClick"
        @update:open="closeMatrix"
    >
        <OrganismMatrix
            :abstract-product-id-or-sku="abstractProductIdOrSku?.toString() || ''"
            :abstract-sku="abstractSku"
            :cart-id="cartId"
            :pdp="false"
            :is-cart-service="isCartService"
            :is-matrix-modal="true"
            @update:loading="isLoading = $event"
            @close:close-matrix="closeMatrix"
        />
    </AtomModal>
</template>

<script lang="ts" setup>
import type { ModalButton } from '~/composables/types/ui'

type ExtendedModalButton = ModalButton & {
    icon?: string
    class?: string
}

type NavList = {
    abstractId: string
    labels?: string[]
}

const { $emitter } = useNuxtApp()
const route = useRoute()
const warningsStore = useMinQuantityWarningsStore()
const toasts = useToasts()

const abstractProductIdOrSku = ref<string | null>(null)
const abstractSku = ref<string>('')
const cartId = ref<string | null>('')
const navList: Ref<NavList[] | null> = ref(null)
const isCartService: Ref<boolean> = ref(false)
const isLoading = ref(true)
const BUTTONS_INDEX = {
    PREV: 0,
    NEXT: 1,
}

const showSideButtons = ref(false)

const routePath = computed(() => route.path)
watch(routePath, () => {
    if (!routePath.value.includes(`${NuxtLinkNameTypes.PRODUCT}/`) || !abstractProductIdOrSku.value) {
        warningsStore.set({})
        toasts.clear('warning')
    }
})

const modalButtons = computed(() => {
    const buttons: ExtendedModalButton[] = []

    if (abstractProductIdOrSku.value && navList.value && String(navList.value).length > 1) {
        const currentIndex = navList.value.findIndex((item) => item.abstractId === abstractProductIdOrSku.value)

        if (currentIndex !== -1) {
            buttons.push({
                icon: 'chevron-left',
                class: ['matrix-modal__nav', 'matrix-modal__nav--prev', currentIndex <= 0 ? 'hidden' : '']
                    .join(' ')
                    .trim(),
                type: 'secondary' as const,
            })

            buttons.push({
                icon: 'chevron-right',
                class: [
                    'matrix-modal__nav',
                    'matrix-modal__nav--next',
                    !(currentIndex < navList.value.length - 1) ? 'hidden' : '',
                ]
                    .join(' ')
                    .trim(),
                type: 'secondary' as const,
            })
        }
    }

    return buttons
})

const closeMatrix = () => {
    abstractProductIdOrSku.value = null
    cartId.value = null
    $emitter.$emit('matrixModal.closed')
    toasts.clear('warning')
}

const updateCartId = (props: any) => {
    cartId.value = props.id
}

const openMatrix = (matrixProps: any) => {
    abstractProductIdOrSku.value = matrixProps.abstractProductIdOrSku
    abstractSku.value = matrixProps.sku
    cartId.value = matrixProps.cartId ?? null
    navList.value = matrixProps.navList ?? null
    isCartService.value = Boolean(matrixProps.isCartService)
    $emitter.$emit('matrixModal.opened')
    showSideButtons.value = (navList.value && navList.value.length > 1) ?? false
}

const onModalButtonClick = (index: number) => {
    switch (index) {
        case BUTTONS_INDEX.PREV:
        case BUTTONS_INDEX.NEXT:
            if (navList.value && abstractProductIdOrSku.value) {
                const currentIndex = navList.value.findIndex((item) => item.abstractId === abstractProductIdOrSku.value)

                if (currentIndex === -1) {
                    return
                }

                const newIndex = index === BUTTONS_INDEX.PREV ? currentIndex - 1 : currentIndex + 1

                if (newIndex < 0 || newIndex >= navList.value.length) {
                    return
                }

                openMatrix({
                    abstractProductIdOrSku: navList.value[newIndex].abstractId,
                    cartId: cartId.value,
                    navList: navList.value,
                    labels: navList.value[newIndex].labels,
                    isCartService: isCartService.value,
                })
            }
            break
        default:
            break
    }
}

onMounted(() => {
    $emitter.$on('openMatrix', openMatrix)
    $emitter.$on('updateCartId', updateCartId)
})

onBeforeUnmount(() => {
    $emitter.$off('openMatrix', openMatrix)
    $emitter.$off('updateCartId', updateCartId)
})
</script>

<style lang="scss" scoped>
:deep(.content) {
    padding-right: sp(s);
}

.matrix-modal {
    &:deep(.modal-box) {
        height: 100%;
    }

    :deep(.inner-modal-height-auto .modal-box) {
        height: auto;
    }

    &:deep(.modal-container) {
        padding-top: rem(36);
        padding-bottom: rem(6);
    }

    &--with-nav:deep(.modal-box) {
        height: 100%;
        padding-left: rem(50);
        padding-right: rem(50);
    }

    &:deep(.modal-box__custom-content) {
        max-height: 100%;
    }

    :deep(.modal-box__button.matrix-modal__nav) {
        position: absolute;
        top: 50%;
        left: 0.5rem;
        width: rem(32);
        height: rem(100);
        margin: rem(-50) 0 0;
        padding: 0;

        &.hidden {
            display: none;
        }
    }

    :deep(.modal-box__button.matrix-modal__nav--next) {
        left: auto;
        right: 0.5rem;
    }

    :deep(.modal-box__close) {
        position: relative;
        height: 0;
        left: rem(35);
        bottom: rem(8);
    }

    :deep(.branch-cluster-modal .modal-box__close) {
        display: flex;
    }

    &--side-buttons {
        :deep(.modal-box__close) {
            position: relative;
            height: 0;
            bottom: rem(10);
            left: rem(10);
            z-index: $setting-zi-icon;
        }
    }

    .content {
        :deep(.matrix-reset-modal),
        :deep(.matrix-redirect-to-orders),
        :deep(.date-modal),
        :deep(.fedas__modal),
        :deep(.net-prices__modal),
        :deep(.branch-cluster-modal) {
            .modal-box {
                height: auto;
            }
        }
    }
}
</style>
