<template>
    <div ref="el" class="header__navigation" @pointerleave="pointerLeave()">
        <div class="container">
            <div v-if="navigation" class="header__list">
                <div
                    class="navigation-item all"
                    data-testid="headerNavigationItemAll"
                    :class="{ active: activeIndex === 0 }"
                    @pointerenter="pointerEnter(0)"
                >
                    <NuxtLink
                        class="link"
                        :to="NuxtLinkNameTypes.CATEGORY"
                        data-long-press-delay="500"
                        @click="linkClicked()"
                        @contextmenu.prevent
                        @long-press="activeIndex = 0"
                    >
                        <AtomIcon name="menu" width="18" />
                        {{ $t('General.all_categories') }}
                    </NuxtLink>
                </div>

                <div
                    v-for="(navigationItem, index) in navigation.children?.slice(0, 7)"
                    :key="navigationItem.id"
                    class="navigation-item"
                    data-testid="headerNavigationItem"
                    :class="{ active: activeIndex === index + 1 }"
                    @pointerenter="pointerEnter(index + 1)"
                >
                    <NuxtLink
                        v-if="navigationItem"
                        class="link"
                        :to="`${NuxtLinkNameTypes.CATEGORY}${navigationItem.url}`"
                        data-long-press-delay="500"
                        @click="linkClicked()"
                        @contextmenu.prevent
                        @long-press="activeIndex = index + 1"
                    >
                        {{ navigationItem.title }}
                    </NuxtLink>
                </div>
                <div
                    v-if="useStore().isNoStore()"
                    class="navigation-item sale"
                    data-testid="headerNavigationItemSale"
                    @pointerenter="pointerLeave"
                >
                    <NuxtLink
                        class="link"
                        :to="NuxtLinkNameTypes.SALES_SEARCH"
                        data-long-press-delay="500"
                        @click="linkClicked()"
                        @contextmenu.prevent
                        @long-press="activeIndex = 0"
                    >
                        {{ $t('General.marketing_action') }}
                    </NuxtLink>
                </div>
            </div>

            <LazyAtomFlyoutMenu
                v-if="activeIndex !== -1"
                :navigation="getNavigationByIndex(Number(activeIndex))"
                :offset="activeIndex === 0 ? 7 : 0"
                @link-clicked="linkClicked"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import 'long-press-event'
import type { NavigationCategory } from '~/composables/types/api/searchDiscover/header'
import { NuxtLinkNameTypes } from '~/composables/types/nuxtHyperlinkTypes'

const navigationStore = useNavigationStore()
const enterDelay = 750

const { storage: navigation } = storeToRefs(navigationStore)
const el = ref()
const activeIndex = ref(-1)
let enterTimeout: ReturnType<typeof setTimeout> | null

const pointerEnter = (index: number) => {
    if (activeIndex.value === -1) {
        if (enterTimeout) {
            clearTimeout(enterTimeout)
        }

        enterTimeout = setTimeout(() => {
            activeIndex.value = index
        }, enterDelay)
    } else {
        activeIndex.value = index
    }
}

const getNavigationByIndex = (index: number): NavigationCategory | null => {
    if (navigation.value) {
        return index === 0 ? navigation.value : navigation.value.children?.[index - 1]
    }

    return navigation.value
}

const pointerLeave = () => {
    if (enterTimeout) {
        clearTimeout(enterTimeout)
    }
    enterTimeout = null
    activeIndex.value = -1
}

const linkClicked = () => {
    activeIndex.value = -1
}

onMounted(async () => {
    await navigationStore.load()

    document.addEventListener('touchstart', (e: TouchEvent) => {
        const targetElement = e.target as HTMLElement
        if (targetElement.closest('.header__navigation') === el.value) {
            return
        }

        activeIndex.value = -1
    })
})
</script>

<style lang="scss">
.header {
    &__navigation {
        position: relative;
        user-select: none;
        -webkit-touch-callout: none;

        @include helper-color-bg(dark-blue);
    }

    &__list {
        text-transform: uppercase;
        white-space: nowrap;
        display: flex;
        flex-direction: row;

        @include helper-font-size(4);
        @include helper-font-weight(regular);
    }

    .navigation-item {
        cursor: pointer;
        position: relative;

        @include helper-color(white);

        & + .navigation-item {
            margin-left: sp(xxs);
        }

        a {
            z-index: 1;
            position: relative;

            @include helper-color(white);
        }

        &.all {
            a,
            svg {
                @include helper-color(state-default);
            }

            &::before {
                content: '';
                width: 100%;
                height: rem(32);
                position: absolute;
                z-index: 0;
                margin: sp(xxs) 0;

                @include helper-color-bg(white);
                @include helper-border-radius(rem(5));
            }

            &.active,
            &:hover {
                @include helper-color-bg(white);
            }
        }

        &.sale {
            &::before {
                content: '';
                width: 100%;
                height: rem(32);
                position: absolute;
                z-index: 0;
                margin: sp(xxs) 0;
                @include helper-border($setting-color-white);
                @include helper-border-radius(rem(5));
            }

            &:hover {
                &::before {
                    @include helper-border($setting-color-light-gray);
                }
            }
        }

        &.active,
        &:hover {
            @include helper-color-bg(light-gray);

            a {
                @include helper-color(state-default);
            }

            .all-background {
                background-color: unset;
            }
        }
    }

    .link {
        position: relative;
        padding: 0 sp(s);

        @include helper-svg-size(18);

        .svg-icon {
            margin-top: rem(-2);
            line-height: 1;
            vertical-align: middle;
        }
    }
}
</style>
