<template>
    <template v-for="(toast, index) in toasts" :key="index">
        <AtomToast v-bind="toast.bind" v-on="toast.on || {}" @close="onClose(toast)" />
    </template>
</template>

<script lang="ts" setup>
import type { ToastStoreItem } from '~/composables/stores/useToast'

const store = useToasts()
const { toasts } = storeToRefs(store)

const onClose = (toast: ToastStoreItem) => {
    store.remove(toasts.value.indexOf(toast))
}

const handleToastEvent = (event: Event) => {
    if (event instanceof CustomEvent) {
        const message = event.detail
        store.add(message)
    }
}

onMounted(() => {
    window.addEventListener('toastMessage', handleToastEvent)
})

onBeforeUnmount(() => {
    window.removeEventListener('toastMessage', handleToastEvent)
})
</script>
